import { CancelTokenSource } from 'axios';
import {
  ApiError,
  ApiResponse,
  FileResponse,
  ImportType,
  Pageable,
  PaginationFields,
  ProductOverview,
  ProductsAggregatorResponse,
  ProductVariantsResponse,
} from 'models';
import { ImportDetails } from 'models';
import { getFilenameFromContentDisposition } from 'utils/network';
import { Nullable } from 'models/utils';
import {
  apiErrorBoundary,
  getPaginationQuery,
  parseApiResponse,
} from 'utils/api';
import fetcher from 'api/fetcher';

export const ENDPOINTS = {
  getAllProducts: (vendorRef?: string): string =>
    `/v1/products-aggregator` +
    (vendorRef != undefined ? `?vendorRef=${vendorRef}` : ''),
  getImports: (type: ImportType, catalogId?: string): string =>
    `/${type}/imports` +
    (catalogId != undefined ? `?catalogId=${catalogId}` : ''),
  getImportStatus: (type: ImportType, importId: string | number): string =>
    `/${type}/imports/${importId}`,
  exportImportErrors: (type: ImportType, importId: string): string =>
    `/${type}/imports/${importId}/errors/export`,
  exportAllProducts: (vendorRef?: string): string =>
    `/products/export` +
    (vendorRef != undefined ? `?vendorRef=${vendorRef}` : ''),
  exportAllInventory: (catalogId?: string): string =>
    `/inventory/export` +
    (catalogId != undefined ? `?catalogId=${catalogId}` : ''),
  getProductsExportUrlById: (id: string): string => `/products/export/${id}`,
  getVariantsByProductId: (id: string) =>
    `/v1/products-aggregator/${id}/variants`,
  getProductOverview: (id: string) => `/v1/products-aggregator/${id}/overview`,
};

type RequestOptions = {
  cancelToken?: CancelTokenSource;
};

export const importFile = async (
  importType: ImportType,
  {
    file,
    cancelToken,
    catalogId,
  }: RequestOptions & { file: File; catalogId: string }
): Promise<ApiResponse<ImportDetails>> =>
  apiErrorBoundary(async () => {
    const formData = new FormData();
    formData.append('file', file, file.name);
    if (catalogId) formData.append('catalogId', catalogId);

    const url = ENDPOINTS.getImports(importType);

    const response = await fetcher<ImportDetails | ApiError>(url, {
      method: 'POST',
      data: formData,
      validateErrorCodes: false,
      cancelToken: cancelToken?.token,
    });

    return parseApiResponse(response);
  }, `Error importing '${importType}' CSV file`);

export const getImports = async (
  importType: ImportType,
  options?: RequestOptions,
  catalogId?: string
): Promise<ApiResponse<ImportDetails[]>> =>
  apiErrorBoundary(async () => {
    const url = ENDPOINTS.getImports(importType, catalogId);

    const response = await fetcher<ImportDetails[]>(url, {
      cancelToken: options?.cancelToken?.token,
    });

    return parseApiResponse(response);
  }, `Error fetching '${importType}' import list`);

export const getImportDetails = async (
  importType: ImportType,
  { id, cancelToken }: RequestOptions & { id: string }
): Promise<ApiResponse<ImportDetails>> =>
  apiErrorBoundary(async () => {
    const url = ENDPOINTS.getImportStatus(importType, id);

    const response = await fetcher<ImportDetails | ApiError>(url, {
      validateErrorCodes: false,
      cancelToken: cancelToken?.token,
    });

    return parseApiResponse(response);
  }, `Error fetching '${importType}' import #${id} details`);

export const getProducts = async (
  {
    pagination,
    cancelToken,
  }: RequestOptions & { pagination?: Nullable<PaginationFields> },
  vendorRef?: string
): Promise<ApiResponse<Pageable<ProductsAggregatorResponse>>> =>
  apiErrorBoundary(async () => {
    const prefix = vendorRef ? '&' : '?';
    const paginationQuery = getPaginationQuery(pagination);
    const url = `${ENDPOINTS.getAllProducts(
      vendorRef
    )}${prefix}${paginationQuery}`;

    const response = await fetcher<Pageable<ProductsAggregatorResponse>>(url, {
      cancelToken: cancelToken?.token,
    });

    return parseApiResponse(response);
  }, `Error fetching all products list with options '${getPaginationQuery(pagination)}'`);

export const getImportErrorsCsv = async (
  importType: ImportType,
  { id, cancelToken }: RequestOptions & { id: string }
): Promise<ApiResponse<FileResponse>> =>
  apiErrorBoundary(async () => {
    const url = ENDPOINTS.exportImportErrors(importType, id);

    const { data, headers, statusCode } = await fetcher<Blob>(url, {
      responseType: 'blob',
      cancelToken: cancelToken?.token,
    });

    return parseApiResponse({
      data: {
        fileData: data,
        fileName: getFilenameFromContentDisposition(
          headers['content-disposition']
        ),
      } as FileResponse,
      statusCode,
    });
  }, `Error fetching '${importType}' import errors CSV`);

export const getInventoryCsvFile = async (
  options?: RequestOptions,
  catalogId?: string
): Promise<ApiResponse<FileResponse>> =>
  apiErrorBoundary(async () => {
    const url = ENDPOINTS.exportAllInventory(catalogId);

    const { data, headers, statusCode } = await fetcher(url, {
      cancelToken: options?.cancelToken?.token,
    });

    return parseApiResponse({
      data: {
        fileData: data,
        fileName: getFilenameFromContentDisposition(
          headers['content-disposition']
        ),
      } as FileResponse,
      statusCode,
    });
  }, `Error fetching inventory list CSV`);

export const requestProductsCsv = async (
  options?: RequestOptions,
  catalogId?: string
): Promise<ApiResponse<FileResponse>> =>
  apiErrorBoundary(async () => {
    const url = ENDPOINTS.exportAllProducts(catalogId);

    const {
      data: { id },
      statusCode,
    } = await fetcher(url, {
      method: 'POST',
      cancelToken: options?.cancelToken?.token,
      data: {},
    });

    return parseApiResponse({
      data: {
        id,
      } as FileResponse,
      statusCode,
    });
  }, `Error fetching products list CSV`);

export const getProductsCsvURL = async (
  id: string,
  options?: RequestOptions
): Promise<ApiResponse<FileResponse>> =>
  apiErrorBoundary(async () => {
    const url = ENDPOINTS.getProductsExportUrlById(id);

    const {
      data: { reportUrl, status } = { reportUrl: '', status: '' },
      statusCode,
    } = await fetcher(url, {
      cancelToken: options?.cancelToken?.token,
    });

    return parseApiResponse({
      data: {
        reportUrl,
        status,
      } as FileResponse,
      statusCode,
    });
  }, `Error fetching products list CSV URL`);

export const getVariantsByProductId = async (
  {
    pagination,
    cancelToken,
  }: RequestOptions & { pagination?: Nullable<PaginationFields> },
  productId: string
): Promise<ApiResponse<Pageable<ProductVariantsResponse>>> =>
  apiErrorBoundary(async () => {
    const paginationQuery = getPaginationQuery(pagination);
    const url = `${ENDPOINTS.getVariantsByProductId(
      productId
    )}?${paginationQuery}`;

    const response = await fetcher<Pageable<ProductVariantsResponse>>(url, {
      cancelToken: cancelToken?.token,
    });

    return parseApiResponse(response);
  }, `Error fetching all variants with options '${getPaginationQuery(pagination)}'`);

export const getProductOverview = async (
  { cancelToken }: RequestOptions,
  productId: string
): Promise<ApiResponse<ProductOverview>> =>
  apiErrorBoundary(async () => {
    const url = `${ENDPOINTS.getProductOverview(productId)}`;
    const response = await fetcher<ProductOverview>(url, {
      cancelToken: cancelToken?.token,
    });

    return parseApiResponse(response);
  }, `Error fetching product overview`);
